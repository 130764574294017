.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
}

.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.title {
  text-align: center;
}

.image {
  width: 75%;
  margin-bottom: 16px;
}

.content {
  margin-bottom: 16px;
}
