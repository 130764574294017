.sectionTitle {
  margin-bottom: 90px;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.divider {
  color: #000;
  background-color: #000;
  height: 1px;
  width: 90%;
}

@media only screen 
  and (min-device-width: 200px) 
  and (max-device-width: 736px) {
    .sectionTitle {
      text-align: center;
      font-size: 2.2rem;
      margin-bottom: 4rem;
    }



}