.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  border-width: 0.5px;
  border-color: #000000;
  background-color: #99b7e8;
  margin: 16px;
  border-style: solid;
  border-radius: 6px;
}
