.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
}

.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.image {
  width: 75%;
  margin-bottom: 16px;
}

.title {
  text-align: center;
}

.description {
  margin-top: 16px;
  text-align: justify;
}

@media only screen 
  and (min-device-width: 200px) 
  and (max-device-width: 736px) {
    .description {
      text-align: start;
      line-height: 1.6rem;
    }

}