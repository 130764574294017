.title {
  margin-bottom: 90px;
}

@media only screen 
  and (min-device-width: 200px) 
  and (max-device-width: 736px) {
    .title {
      text-align: center;
      font-size: 2.2rem;
      margin-bottom: 4rem;
    }
}