.contactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  margin-left: 0;
}

.button {
  color: #fff;
  font-size: 25px;
  transition: 0.2s;
}

.button:hover {
  color: #909090;
}

.button:active {
  transform: scale(0.82);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  background-color: #212121;
}

.navButton {
  color: #fff;
  transition: 0.2s;
}

.navButton:hover {
  color: #909090;
}

.navButton:active {
  transform: scale(0.82);
}

@media only screen 
  and (min-device-width: 200px) 
  and (max-device-width: 736px) {
    .button {
      font-size: 0.5rem;
    }


}